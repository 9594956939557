.sliderSection {
    &_item {
        position: relative;
        .videoSlider {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            iframe {
                width: 100%;
                height: 100%;
            }
            .btn-play {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                width: 90px;
                height: 60px;
                cursor: pointer;
                background: #fff;
                border-radius: 5px;
                transition: 0.4s ease;
                transform: translate3d(-50%, -50%, 0);
                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 0;
                    height: 0;
                    border-color: transparent transparent transparent $mainColor;
                    border-style: solid;
                    border-width: 18px 0 18px 30px;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }
        .img-bg {
            &::after {
                padding-top: 448/710 * 100%;
            }
        }
    }
    &_article {
        position: absolute;
        right: 20px;
        bottom: 20px;
        left: 20px;
        padding: 10px;
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.4);

        .title {
            display: inline-block;
            font-size: 18px;
            color: #fff;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .desc {
            display: inline-block;
            padding: 3px;
            color: #fff;
            display: -webkit-box;
            overflow: hidden;
            font-size: 14px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    .slick-arrow {
        width: 40px;
        height: 40px;
        background-color: #000 !important;
        border-radius: 0;
        @include media-breakpoint-up(md) {
            top: 20px;
        }
        &::before {
            top: 56%;
            display: inline-block;
            width: 14px;
            height: 14px;
            padding: 3px;
            border: solid #fff;
            border-width: 0 3px 3px 0;
        }
        &.slick-prev {
            @include media-breakpoint-up(md) {
                left: 20px;
            }
            &::before {
                left: 63%;
                transform: rotate(135deg);
            }
        }
        &.slick-next {
            @include media-breakpoint-up(md) {
                right: auto;
                left: 70px;
            }
            &::before {
                transform: rotate(315deg);
            }
        }
    }
}

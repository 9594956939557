.postPanel {
    &_inner {
        position: relative;
        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
            height: 7px;
            -webkit-overflow-scrolling: auto;
        }
        ::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    }
    &_title {
        position: relative;
        padding: 0;
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        background: none repeat scroll 0 0 #354b9c;
        h2 {
            // background: none repeat scroll 0 0 #d083cf;
            position: relative;
            display: inline-block;
            padding: 4px 10px;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            text-transform: uppercase;
            @include media-breakpoint-down(lg) {
                font-size: 15px;
            }
            span {
                position: relative;
                z-index: 2;
            }
            &::before {
                position: absolute;
                top: -8px;
                right: 0;
                bottom: -8px;
                left: 0;
                z-index: 1;
                content: '';
                background: none repeat scroll 0 0 #d083cf;
            }
        }
    }
    &_lists {
        max-height: 403px;
        padding-left: 0;
        margin-bottom: 0;
        overflow: auto;
        &_item {
            display: flex;
            &:not(:last-child) {
                margin-bottom: 14px;
            }
            &_img {
                margin-right: 8px;
                overflow: hidden;
                &:hover {
                    .img-bg {
                        transform: scale(1.12);
                    }
                }
                .img-bg {
                    width: 90px;
                    transition: 0.4s ease;
                    &::after {
                        padding-top: 70/90 * 100%;
                    }
                }
            }
            &_text {
                flex: 1 0 0;
                a {
                    font-weight: 300;
                    color: #2f2f2f;
                    text-decoration: none;
                    display: -webkit-box;
                    overflow: hidden;
                    font-size: 14px;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    &:hover {
                        color: $mainColor;
                    }
                }
            }
        }
    }
}
.verticalPost {
    &_img {
        overflow: hidden;
        &:hover {
            .img-bg {
                transform: scale(1.12);
            }
        }
        .img-bg {
            transition: 0.4s ease;
            &::after {
                padding-top: 283/425 * 100%;
            }
        }
    }
    &_context {
        margin-top: 14px;
        a {
            display: -webkit-box;
            overflow: hidden;
            font-weight: 600;
            color: #2f2f2f;
            text-decoration: none;
            transition: 0.4s ease;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &:hover {
                color: $mainColor;
            }
        }
        p {
            display: -webkit-box;
            margin-bottom: 0;
            overflow: hidden;
            font-size: 14px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}

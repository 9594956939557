// Sample code
.footer {
    padding: 50px 0;
    color: #fff;
    background-color: $mainColor;
    .f-title {
        // text-align: center;
    }
    .logoFooter {
        margin-bottom: 14px;
    }
    .nav-footer {
        padding-left: 0;
        margin-top: 0;
        margin-top: 12px;
        list-style: none;
        li {
            margin-bottom: 6px;
            font-size: 14px;
            a {
                color: #fff;
            }
        }
    }
    .nav-social {
        display: flex;
        padding-left: 0;
        margin-top: 0;
        margin-top: 12px;
        list-style: none;
        li {
            margin-right: 10px;
            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                background-color: #fff;
                border-radius: 2px;
                &:hover {
                    mix-blend-mode: hard-light;
                }
            }
        }
    }
}
.copyright-footer {
    padding: 8px 0;
    background-color: #091544;
}

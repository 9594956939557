/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
    padding: 0;
    margin: 0;
    vertical-align: top;
    border: 0;
    outline: none;
}
.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.fancybox-skin {
    position: relative;
    color: #444;
    text-shadow: none;
    background: #f9f9f9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.fancybox-opened {
    z-index: 8030;
}
.fancybox-opened .fancybox-skin {
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
    position: relative;
}
.fancybox-inner {
    overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}
.fancybox-error {
    padding: 15px;
    margin: 0;
    font: 14px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #444;
    white-space: nowrap;
}
.fancybox-image,
.fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}
.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
    background-image: url('../../images/fancybox_sprite.png');
}
#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 8060;
    margin-top: -22px;
    margin-left: -22px;
    cursor: pointer;
    background-position: 0 -108px;
    opacity: 0.8;
}
#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url('../../images/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
    position: absolute;
    top: -18px;
    right: -18px;
    z-index: 8040;
    width: 36px;
    height: 36px;
    cursor: pointer;
}
.fancybox-nav {
    position: absolute;
    top: 0;
    z-index: 8040;
    width: 40%;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    background: transparent url('../../images/blank.gif'); /* helps IE */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fancybox-prev {
    left: 0;
}
.fancybox-next {
    right: 0;
}
.fancybox-nav span {
    position: absolute;
    top: 50%;
    z-index: 8040;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    cursor: pointer;
    visibility: hidden;
}
.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px;
}
.fancybox-next span {
    right: 10px;
    background-position: 0 -72px;
}
.fancybox-nav:hover span {
    visibility: visible;
}
.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important;
}
/* Overlay helper */
.fancybox-lock {
    width: auto;
    overflow: visible !important;
}
.fancybox-lock body {
    overflow: hidden !important;
}
.fancybox-lock-test {
    overflow-y: hidden !important;
}
.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8010;
    display: none;
    overflow: hidden;
    background: url('../../images/fancybox_overlay.png');
}
.fancybox-overlay-fixed {
    position: fixed;
    right: 0;
    bottom: 0;
}
.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
    position: relative;
    z-index: 8050;
    font: normal 13px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-shadow: none;
    visibility: hidden;
}
.fancybox-opened .fancybox-title {
    visibility: visible;
}
.fancybox-title-float-wrap {
    position: absolute;
    right: 50%;
    bottom: 0;
    z-index: 8050;
    margin-bottom: -35px;
    text-align: center;
}
.fancybox-title-float-wrap .child {
    display: inline-block;
    padding: 2px 20px;
    margin-right: -100%;
    font-weight: bold;
    line-height: 24px;
    color: #fff;
    text-shadow: 0 1px 2px #222;
    white-space: nowrap;
    background: transparent; /* Fallback for web browsers that doesn't support RGBa */
    background: rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}
.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}
.fancybox-title-inside-wrap {
    padding-top: 10px;
}
.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    color: #fff;
    background: #000;
    background: rgba(0, 0, 0, 0.8);
}
/* Retina graphics! */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    #fancybox-loading,
    .fancybox-close,
    .fancybox-prev span,
    .fancybox-next span {
        background-image: url('../../images/fancybox_sprite@2x.png');
        background-size: 44px 152px; /* The size of the normal image, half the size of the hi-res image */
    }
    #fancybox-loading div {
        background-image: url('../../images/fancybox_loading@2x.gif');
        background-size: 24px 24px; /* The size of the normal image, half the size of the hi-res image */
    }
}

.s-category {
    .imgPanel {
        overflow: hidden;
        .img {
            &:not(:last-child) {
                margin-bottom: 14px;
            }
            .img-bg {
                &::after {
                    padding-top: 122/320 * 100%;
                }
            }
        }
    }
}

.s-introLatest {
    &_latest {
        margin-bottom: 30px;
        @include break-max(991.99px) {
            margin-top: 20px;
        }
    }
    .showCaseSection {
        padding: 30px;
        margin-bottom: 40px;
        background: #ffde76;
        .articlePanel {
            &_top {
                margin-bottom: 16px;
                .customRow {
                    .col-lg-6 {
                        &:first-child {
                            @include media-breakpoint-down(lg) {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
                .articleThumbnail {
                    .img-bg {
                        &::after {
                            padding-top: 317/476 * 100%;
                        }
                    }
                    a.title {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        left: 10px;
                        display: -webkit-box;
                        overflow: hidden;
                        font-size: 18px;
                        font-weight: bold;
                        color: #fff;
                        text-decoration: none;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            .articleSlider {
                margin-right: -8px;
                margin-left: -8px;
                .verticalPost {
                    padding-right: 8px;
                    padding-left: 8px;
                }
            }
            .articleThumbnail {
                position: relative;
            }
            .verticalPost_context {
                margin-top: 4px;
                a {
                    line-height: 1.2;
                }
            }
        }
    }
}

.slick-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: black;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease
}
.slick-lightbox .slick-loading .slick-list {
    background-color: transparent
}
.slick-lightbox .slick-prev {
    left: 15px
}
.slick-lightbox .slick-next {
    right: 15px
}
.slick-lightbox-hide {
    opacity: 0
}
.slick-lightbox-hide.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0)
}
.slick-lightbox-hide-init {
    position: absolute;
    top: -9999px;
    opacity: 0
}
.slick-lightbox-hide-init.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0)
}
.slick-lightbox-inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
.slick-lightbox-slick-item {
    overflow: hidden;
    text-align: center
}
.slick-lightbox-slick-item::before {
    display: inline-block;
    height: 100%;
    margin-right: -0.25em;
    vertical-align: middle;
    content: ''
}
.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 20px;
    text-align: center
}
.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    display: block;
    text-align: center
}
.slick-lightbox-slick-item-inner {
    display: inline-block;
    max-width: 90%;
    max-height: 90%;
    vertical-align: middle
}
.slick-lightbox-slick-img {
    display: block;
    max-width: 90%;
    max-height: 90%;
    margin: 0 auto
}
.slick-lightbox-slick-caption {
    margin: 10px 0 0;
    color: white
}
.slick-lightbox-close {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 0;
    line-height: 0;
    color: transparent;
    cursor: pointer;
    background: transparent;
    border: none
}
.slick-lightbox-close:focus {
    outline: none
}
.slick-lightbox-close::before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    content: '×';
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

// stylelint-disable no-duplicate-selectors, selector-max-combinators, selector-max-compound-selectors, selector-max-type, selector-no-qualifying-type
$bd-purple: #563d7c !default;
$bd-purple-bright: lighten(saturate($bd-purple, 5%), 15%) !default;
$bd-purple-light: lighten(saturate($bd-purple, 5%), 45%) !default;
$bd-dark: #2a2730 !default;
$bd-download: #ffe484 !default;
$bd-info: #5bc0de !default;
$bd-warning: #f0ad4e !default;
$bd-danger: #d9534f !default;
//
// Main navbar
//
.bd-navbar {
    min-height: 4rem;
    background-color: $bd-purple;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    @include media-breakpoint-down(md) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        .navbar-nav-scroll {
            max-width: 100%;
            height: 2.5rem;
            margin-top: 0.25rem;
            overflow: hidden;
            font-size: 0.875rem;
            .navbar-nav {
                padding-bottom: 2rem;
                overflow-x: auto;
                white-space: nowrap;
                -webkit-overflow-scrolling: touch;
            }
        }
    }
    @include media-breakpoint-up(md) {
        @supports (position: sticky) {
            position: sticky;
            top: 0;
            z-index: 1020; // over everything in bootstrap
        }
    }
    .navbar-nav {
        .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            color: $bd-purple-light;
            &.active,
            &:hover {
                color: #fff;
                background-color: transparent;
            }
            &.active {
                font-weight: 500;
            }
        }
    }
    .navbar-nav-svg {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        vertical-align: text-top;
    }
    .dropdown-menu {
        font-size: 0.875rem;
    }
    .dropdown-item.active {
        font-weight: 500;
        color: $gray-900;
        background-color: transparent;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 0.4rem 0.6rem;
        background-size: 0.75rem 0.75rem;
    }
}

//
// Automatically style Markdown-based tables like a Bootstrap `.table`.
//
.bd-content {
    order: 1; // Hack the sticky header
    > h2[id],
    > h3[id],
    > h4[id] {
        pointer-events: none;
        > div,
        > a {
            pointer-events: auto;
        }
        &::before {
            display: block;
            height: 6rem;
            margin-top: -6rem;
            visibility: hidden;
            content: '';
        }
    }
    > table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        @include media-breakpoint-down(md) {
            display: block;
            overflow-x: auto;
            -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
            &.table-bordered {
                border: 0;
            }
        } // Cells
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    padding: $table-cell-padding;
                    vertical-align: top;
                    border: 1px solid $table-border-color;
                    > p:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        } // Prevent breaking of code (e.g., Grunt tasks list)
        td:first-child > code {
            white-space: nowrap;
        }
    }
    .continuous-false,
    .continuous-true,
    .continuous-group-left,
    .continuous-group-right,
    .continuous-mix-true,
    .continuous-mix-false {
        height: 80px;
        padding: 10px;
        margin: 15px 0;
        color: #fff;
        background: #555;
    }
    .waypoint {
        box-shadow: 0 0 0 5px rgba(104, 164, 43, 0.5);
        &.disabled {
            box-shadow: 0 0 0 5px rgba(192, 52, 52, 0.5);
        }
    }
    .scroll-down {
        position: relative;
        height: 700px;
        height: 50vh;
        font-size: 2em;
        font-weight: 300;
        color: #ccc;
        text-align: center;
        &::before,
        &::after {
            display: block;
            content: '\2193';
        }
    }
    .highlight {
        position: relative;
        &.stuck {
            position: fixed;
            width: calc(100% - 30px);
            @media (min-width: 768px) {
                width: calc(75% - 3rem - 15px);
            }
            @media (min-width: 1200px) {
                width: calc(66.6666666667% - 3rem - 15px);
            }
            box-shadow: 0 0 0 5px rgba(104, 164, 43, 0.5);
        }
        > .button-group,
        > .btn {
            position: absolute;
            right: 0;
            bottom: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .np-left {
        height: 100px;
        margin: 10px 0;
        background: #555;
    }
    .np-right {
        height: 231px;
        margin: 16px 0;
        background: #aaa;
    }
    .np-previous {
        background: $danger;
    }
    .np-current {
        background: $success;
    }
    .np-next {
        background: $primary;
    }
    .infinite-item {
        padding: 40px 10px;
        margin-top: 10px;
        color: #fff;
        background: #555;
        &:first-child {
            margin-top: 0;
        }
    }
    .infinite-container {
        padding: 10px;
        margin-top: 32px;
    }
} //
// Docs sections
//
.bd-content {
    > h2:not(:first-child) {
        margin-top: 3rem;
    }
    > h3 {
        margin-top: 1.5rem;
    }
    > ul li,
    > ol li {
        margin-bottom: 0.25rem;
    }
    @include media-breakpoint-up(lg) {
        > ul,
        > ol,
        > p {
            max-width: 80%;
        }
    }
}
.bd-title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 300;
    @include media-breakpoint-up(sm) {
        font-size: 3rem;
    }
}
.bd-lead {
    font-size: 1.125rem;
    font-weight: 300;
    @include media-breakpoint-up(sm) {
        max-width: 80%;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }
}
.bd-text-purple {
    color: $bd-purple;
}
.bd-text-purple-bright {
    color: $bd-purple-bright;
}

//
// Grid examples
//
.bd-example-row {
    .row {
        > .col,
        > [class^='col-'] {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            background-color: rgba(86, 61, 124, 0.15);
            border: 1px solid rgba(86, 61, 124, 0.2);
        }
    }
    .row + .row {
        margin-top: 1rem;
    }
    .flex-items-top,
    .flex-items-middle,
    .flex-items-bottom {
        min-height: 6rem;
        background-color: rgba(255, 0, 0, 0.1);
    }
}
.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, 0.1);
}
.bd-highlight {
    background-color: rgba($bd-purple, 0.15);
    border: 1px solid rgba($bd-purple, 0.15);
}

// Grid mixins
.example-container {
    width: 800px;
    @include make-container();
}
.example-row {
    @include make-row();
}
.example-content-main {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(8);
    }
}
.example-content-secondary {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(4);
    }
}

//
// Container illustrations
//
.bd-example-container {
    min-width: 16rem;
    max-width: 25rem;
    margin-right: auto;
    margin-left: auto;
}
.bd-example-container-header {
    height: 3rem;
    margin-bottom: 0.5rem;
    background-color: lighten($blue, 50%);
    border-radius: 0.25rem;
}
.bd-example-container-sidebar {
    float: right;
    width: 4rem;
    height: 8rem;
    background-color: lighten($blue, 25%);
    border-radius: 0.25rem;
}
.bd-example-container-body {
    height: 8rem;
    margin-right: 4.5rem;
    background-color: lighten($bd-purple, 25%);
    border-radius: 0.25rem;
}
.bd-example-container-fluid {
    max-width: none;
}

//
// Docs examples
//
.bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem (-$grid-gutter-width / 2) 0;
    border: solid $gray-100;
    border-width: 0.2rem 0 0;
    @include clearfix();
    @include media-breakpoint-up(sm) {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 0.2rem;
    }
    + .highlight,
    + .clipboard + .highlight {
        margin-top: 0;
    }
    + p {
        margin-top: 2rem;
    }
    > p:last-child {
        margin-bottom: 0;
    }
    .pos-f-t {
        position: relative;
        margin: -1rem;
        @include media-breakpoint-up(sm) {
            margin: -1.5rem;
        }
    }
    .custom-file-input:lang(es) ~ .custom-file-label::after {
        content: 'Elegir';
    }
    > .form-control {
        + .form-control {
            margin-top: 0.5rem;
        }
    }
    > .nav + .nav,
    > .alert + .alert,
    > .navbar + .navbar,
    > .progress + .progress,
    > .progress + .btn {
        margin-top: 1rem;
    }
    > .dropdown-menu:first-child {
        position: static;
        display: block;
    }
    > .form-group:last-child {
        margin-bottom: 0;
    }
    > .close {
        float: none;
    }
}

// Typography
.bd-example-type {
    .table {
        .type-info {
            color: #999;
            vertical-align: middle;
        }
        td {
            padding: 1rem 0;
            border-color: #eee;
        }
        tr:first-child td {
            border-top: 0;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// Contextual background colors
.bd-example-bg-classes p {
    padding: 1rem;
}

// Images
.bd-example > img {
    + img {
        margin-left: 0.5rem;
    }
}

// Buttons
.bd-example {
    > .btn-group {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }
    > .btn-toolbar + .btn-toolbar {
        margin-top: 0.5rem;
    }
}

// Forms
.bd-example-control-sizing select,
.bd-example-control-sizing input[type='text'] + input[type='text'] {
    margin-top: 0.5rem;
}
.bd-example-form .input-group {
    margin-bottom: 0.5rem;
}
.bd-example > textarea.form-control {
    resize: vertical;
}

// List groups
.bd-example > .list-group {
    max-width: 400px;
}

// Navbars
.bd-example {
    .fixed-top,
    .sticky-top {
        position: static;
        margin: -1rem -1rem 1rem;
    }
    .fixed-bottom {
        position: static;
        margin: 1rem -1rem -1rem;
    }
    @include media-breakpoint-up(sm) {
        .fixed-top,
        .sticky-top {
            margin: -1.5rem -1.5rem 1rem;
        }
        .fixed-bottom {
            margin: 1rem -1.5rem -1.5rem;
        }
    }
}

// Pagination
.bd-example .pagination {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

// Example modals
.modal {
    z-index: 1072;
    .tooltip,
    .popover {
        z-index: 1073;
    }
}
.modal-backdrop {
    z-index: 1071;
}
.bd-example-modal {
    background-color: #fafafa;
    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
        opacity: 1;
        .modal-dialog {
            left: auto;
            margin-right: auto;
            margin-left: auto;
            transform: translateY(0);
        }
    }
}

// Example tabbable tabs
.bd-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}

// Popovers
.bd-example-popover-static {
    padding-bottom: 1.5rem;
    background-color: #f9f9f9;
    .popover {
        position: relative;
        display: block;
        float: left;
        width: 260px;
        margin: 1.25rem;
    }
}

// Tooltips
.tooltip-demo a {
    white-space: nowrap;
}
.bd-example-tooltip-static .tooltip {
    position: relative;
    display: inline-block;
    margin: 10px 20px;
    opacity: 1;
}

// Scrollspy demo on fixed height div
.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: 0.5rem;
    overflow: auto;
}
.scrollspy-example-2 {
    position: relative;
    height: 350px;
    overflow: auto;
}
.bd-example-border-utils {
    [class^='border'] {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        margin: 0.25rem;
        background-color: #f5f5f5;
    }
}
.bd-example-border-utils-0 {
    [class^='border'] {
        border: 1px solid $border-color;
    }
}

//
// Code snippets
//
.highlight {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: $gray-100;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include media-breakpoint-up(sm) {
        padding: 1.5rem;
    }
    + .highlight,
    + .bd-clipboard + .highlight {
        margin-top: -1rem;
        border-top: 1px solid #fff;
    }
}
.bd-content .highlight {
    margin-right: (-$grid-gutter-width / 2);
    margin-left: (-$grid-gutter-width / 2);
    @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 0;
    }
}
.highlight {
    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
    }
    // pre code {
    //     // font-size: inherit;
    //     // color: $gray-900; // Effectively the base text color
    // }
}

//
// Grid examples
//
.bd-example-row {
    .row {
        > .col,
        > [class^='col-'] {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            background-color: rgba(86, 61, 124, 0.15);
            border: 1px solid rgba(86, 61, 124, 0.2);
        }
    }
    .row + .row {
        margin-top: 1rem;
    }
    .flex-items-top,
    .flex-items-middle,
    .flex-items-bottom {
        min-height: 6rem;
        background-color: rgba(255, 0, 0, 0.1);
    }
}
.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, 0.1);
}
.bd-highlight {
    background-color: rgba($bd-purple, 0.15);
    border: 1px solid rgba($bd-purple, 0.15);
}

// Grid mixins
.example-container {
    width: 800px;
    @include make-container();
}
.example-row {
    @include make-row();
}
.example-content-main {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(8);
    }
}
.example-content-secondary {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(4);
    }
}

//
// Container illustrations
//
.bd-example-container {
    min-width: 16rem;
    max-width: 25rem;
    margin-right: auto;
    margin-left: auto;
}
.bd-example-container-header {
    height: 3rem;
    margin-bottom: 0.5rem;
    background-color: lighten($blue, 50%);
    border-radius: 0.25rem;
}
.bd-example-container-sidebar {
    float: right;
    width: 4rem;
    height: 8rem;
    background-color: lighten($blue, 25%);
    border-radius: 0.25rem;
}
.bd-example-container-body {
    height: 8rem;
    margin-right: 4.5rem;
    background-color: lighten($bd-purple, 25%);
    border-radius: 0.25rem;
}
.bd-example-container-fluid {
    max-width: none;
}

//
// Docs examples
//
.bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem (-$grid-gutter-width / 2) 0;
    border: solid $gray-100;
    border-width: 0.2rem 0 0;
    @include clearfix();
    @include media-breakpoint-up(sm) {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 0.2rem;
    }
    + .highlight,
    + .clipboard + .highlight {
        margin-top: 0;
    }
    + p {
        margin-top: 2rem;
    }
    .pos-f-t {
        position: relative;
        margin: -1rem;
        @include media-breakpoint-up(sm) {
            margin: -1.5rem;
        }
    }
    .custom-file-input:lang(es) ~ .custom-file-label::after {
        content: 'Elegir';
    }
    > .form-control {
        + .form-control {
            margin-top: 0.5rem;
        }
    }
    > .nav + .nav,
    > .alert + .alert,
    > .navbar + .navbar,
    > .progress + .progress,
    > .progress + .btn {
        margin-top: 1rem;
    }
    > .dropdown-menu:first-child {
        position: static;
        display: block;
    }
    > .form-group:last-child {
        margin-bottom: 0;
    }
    > .close {
        float: none;
    }
}

// Typography
.bd-example-type {
    .table {
        .type-info {
            color: #999;
            vertical-align: middle;
        }
        td {
            padding: 1rem 0;
            border-color: #eee;
        }
        tr:first-child td {
            border-top: 0;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// Contextual background colors
.bd-example-bg-classes p {
    padding: 1rem;
}

// Images
.bd-example > img {
    + img {
        margin-left: 0.5rem;
    }
}

// Buttons
.bd-example {
    > .btn-group {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }
    > .btn-toolbar + .btn-toolbar {
        margin-top: 0.5rem;
    }
}

// Forms
.bd-example-control-sizing select,
.bd-example-control-sizing input[type='text'] + input[type='text'] {
    margin-top: 0.5rem;
}
.bd-example-form .input-group {
    margin-bottom: 0.5rem;
}
.bd-example > textarea.form-control {
    resize: vertical;
}

// List groups
.bd-example > .list-group {
    max-width: 400px;
}

// Navbars
.bd-example {
    .fixed-top,
    .sticky-top {
        position: static;
        margin: -1rem -1rem 1rem;
    }
    .fixed-bottom {
        position: static;
        margin: 1rem -1rem -1rem;
    }
    @include media-breakpoint-up(sm) {
        .fixed-top,
        .sticky-top {
            margin: -1.5rem -1.5rem 1rem;
        }
        .fixed-bottom {
            margin: 1rem -1.5rem -1.5rem;
        }
    }
}

// Pagination
.bd-example .pagination {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

// Example modals
.modal {
    z-index: 1072;
    .tooltip,
    .popover {
        z-index: 1073;
    }
}
.modal-backdrop {
    z-index: 1071;
}
.bd-example-modal {
    background-color: #fafafa;
    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
    }
    .modal-dialog {
        left: auto;
        margin-right: auto;
        margin-left: auto;
    }
}

// Example tabbable tabs
.bd-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}

// Popovers
.bd-example-popover-static {
    padding-bottom: 1.5rem;
    background-color: #f9f9f9;
    .popover {
        position: relative;
        display: block;
        float: left;
        width: 260px;
        margin: 1.25rem;
    }
}

// Tooltips
.tooltip-demo a {
    white-space: nowrap;
}
.bd-example-tooltip-static .tooltip {
    position: relative;
    display: inline-block;
    margin: 10px 20px;
    opacity: 1;
}

// Scrollspy demo on fixed height div
.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: 0.5rem;
    overflow: auto;
}
.scrollspy-example-2 {
    position: relative;
    height: 350px;
    overflow: auto;
}
.bd-example-border-utils {
    [class^='border'] {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        margin: 0.25rem;
        background-color: #f5f5f5;
    }
}
.bd-example-border-utils-0 {
    [class^='border'] {
        border: 1px solid $border-color;
    }
}

//
// Code snippets
//
.highlight {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: $gray-100;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include media-breakpoint-up(sm) {
        padding: 1.5rem;
    }
}
.bd-content .highlight {
    margin-right: (-$grid-gutter-width / 2);
    margin-left: (-$grid-gutter-width / 2);
    @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 0;
    }
}
.highlight {
    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
    }
    pre code {
        // font-size: inherit;
        // color: $gray-900; // Effectively the base text color
        &.hljs {
            background: transparent;
        }
    }
}

// Inline and block code styles
code,
kbd,
pre,
samp {
    font-family: $font-family-monospace;
}

// Inline code
code {
    font-size: $code-font-size;
    color: $code-color;
    word-break: break-word; // Streamline the style when inside anchors to avoid broken underline and more
    a > & {
        color: inherit;
    }
}

// User input typically entered via keyboard
kbd {
    padding: $kbd-padding-y $kbd-padding-x;
    font-size: $kbd-font-size;
    color: $kbd-color;
    background-color: $kbd-bg;
    @include border-radius($border-radius-sm);
    @include box-shadow($kbd-box-shadow);
    kbd {
        padding: 0;
        font-size: 100%;
        font-weight: $nested-kbd-font-weight;
        @include box-shadow(none);
    }
}

// Blocks of code
pre {
    display: block;
    font-size: $code-font-size;
    color: $pre-color; // Account for some code outputs that place code tags in pre tags
    code {
        font-size: inherit;
        color: inherit;
        word-break: normal;
    }
}

// Enable scrollable blocks of code
.pre-scrollable {
    max-height: $pre-scrollable-max-height;
    overflow-y: scroll;
}

// clipboard.js
//
// JS-based `Copy` buttons for code snippets.
.bd-clipboard {
    position: relative;
    display: none;
    float: right;
    + .highlight {
        margin-top: 0;
    }
}
.btn-clipboard {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 10;
    display: block;
    padding: 0.25rem 0.5rem;
    font-size: 75%;
    color: #818a91;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    border-radius: 0.25rem;
    &:hover {
        color: #fff;
        background-color: #027de7;
    }
}
@media (min-width: 768px) {
    .bd-clipboard {
        display: block;
    }
}

// stylelint-disable declaration-no-important
//
// Right side table of contents
//
.bd-toc {
    @supports (position: sticky) {
        position: sticky;
        top: 4rem;
        height: calc(100vh - 4rem);
        overflow-y: auto;
    }
    order: 2;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 0.875rem;
}
.section-nav {
    padding-left: 0;
    border-left: 1px solid #eee;
    ul {
        padding-left: 1rem;
    }
}
.toc-entry {
    display: block;
    a {
        display: block;
        padding: 0.125rem 1.5rem;
        color: #99979c;
        &:hover {
            color: $blue;
            text-decoration: none;
        }
    }
}

//
// Left side navigation
//
.bd-sidebar {
    order: 0; // background-color: #f5f2f9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include media-breakpoint-up(md) {
        @supports (position: sticky) {
            position: sticky;
            top: 4rem;
            z-index: 1000;
            height: calc(100vh - 4rem);
        }
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    @include media-breakpoint-up(xl) {
        flex: 0 1 320px;
    }
}
.bd-links {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-right: -15px;
    margin-left: -15px;
    @include media-breakpoint-up(md) {
        @supports (position: sticky) {
            max-height: calc(100vh - 9rem);
            overflow-y: auto;
        }
    } // Override collapse behaviors
    @include media-breakpoint-up(md) {
        display: block !important;
    }
}
.bd-search {
    position: relative; // To contain the Algolia search
    padding: 1rem 15px;
    margin-right: -15px;
    margin-left: -15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    .form-control:focus {
        border-color: $bd-purple-bright;
        box-shadow: 0 0 0 3px rgba($bd-purple-bright, 0.25);
    }
}
.bd-search-docs-toggle {
    line-height: 1;
    color: $gray-900;
}
.bd-sidenav {
    display: none;
}
.bd-toc-link {
    display: block;
    padding: 0.25rem 1.5rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
    &:hover {
        color: rgba(0, 0, 0, 0.85);
        text-decoration: none;
    }
}
.bd-toc-item {
    &.active {
        margin-bottom: 1rem;
        &:not(:first-child) {
            margin-top: 1rem;
        }
        > .bd-toc-link {
            color: rgba(0, 0, 0, 0.85);
            &:hover {
                background-color: transparent;
            }
        }
        > .bd-sidenav {
            display: block;
        }
    }
}

// All levels of nav
.bd-sidebar .nav > li > a {
    display: block;
    padding: 0.25rem 1.5rem;
    font-size: 90%;
    color: rgba(0, 0, 0, 0.65);
}
.bd-sidebar .nav > li > a:hover {
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
    background-color: transparent;
}
.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    background-color: transparent;
}

//
// Footer
//
.bd-footer {
    font-size: 85%;
    text-align: center;
    background-color: #f7f7f7;
    a {
        font-weight: 500;
        color: $gray-700;
        &:hover,
        &:focus {
            color: $link-color;
        }
    }
    p {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(sm) {
        text-align: left;
    }
}
.bd-footer-links {
    padding-left: 0;
    margin-bottom: 1rem;
    li {
        display: inline-block;
        + li {
            margin-left: 1rem;
        }
    }
}

// Buttons
//
// Custom buttons for the docs.
.btn-bd-primary {
    font-weight: 500;
    color: $bd-purple-bright;
    border-color: $bd-purple-bright;
    &:hover,
    &:active {
        color: #fff;
        background-color: $bd-purple-bright;
        border-color: $bd-purple-bright;
    }
    &:focus {
        box-shadow: 0 0 0 3px rgba($bd-purple-bright, 0.25);
    }
}
.btn-bd-download {
    font-weight: 500;
    color: $bd-download;
    border-color: $bd-download;
    &:hover,
    &:active {
        color: $bd-dark;
        background-color: $bd-download;
        border-color: $bd-download;
    }
    &:focus {
        box-shadow: 0 0 0 3px rgba($bd-download, 0.25);
    }
}

// Docsearch overrides
//
// `!important` indicates overridden properties.
.algolia-autocomplete {
    display: block !important;
    flex: 1; // Menu container
    .ds-dropdown-menu {
        width: 100%;
        min-width: 0 !important;
        max-width: none !important;
        padding: 0.75rem 0 !important;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
        @include media-breakpoint-up(md) {
            width: 175%;
        } // Caret
        &::before {
            display: none !important;
        }
        [class^='ds-dataset-'] {
            padding: 0 !important;
            overflow: visible !important;
            background-color: transparent !important;
            border: 0 !important;
        }
        .ds-suggestions {
            margin-top: 0 !important;
        }
    }
    .algolia-docsearch-suggestion {
        padding: 0 !important;
        overflow: visible !important;
    }
    .algolia-docsearch-suggestion--category-header {
        padding: 0.125rem 1rem !important;
        margin-top: 0 !important;
        font-size: 0.875rem !important;
        font-weight: 500 !important;
        color: $bd-purple-bright !important;
        border-bottom: 0 !important;
    }
    .algolia-docsearch-suggestion--wrapper {
        float: none !important;
        padding-top: 0 !important;
    } // Section header
    .algolia-docsearch-suggestion--subcategory-column {
        float: none !important;
        width: auto !important;
        padding: 0 !important;
        text-align: left !important;
    }
    .algolia-docsearch-suggestion--content {
        float: none !important;
        width: auto !important;
        padding: 0 !important; // Vertical divider between column header and content
        &::before {
            display: none !important;
        }
    }
    .ds-suggestion {
        &:not(:first-child) {
            .algolia-docsearch-suggestion--category-header {
                padding-top: 0.75rem !important;
                margin-top: 0.75rem !important;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
        .algolia-docsearch-suggestion--subcategory-column {
            display: none !important;
        }
    }
    .algolia-docsearch-suggestion--title {
        display: block;
        padding: 0.25rem 1rem !important;
        margin-bottom: 0 !important;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
    }
    .algolia-docsearch-suggestion--text {
        padding: 0 1rem 0.5rem !important;
        margin-top: -0.25rem;
        font-size: 0.875rem !important;
        font-weight: 400;
        line-height: 1.25 !important;
    }
    .algolia-docsearch-footer {
        float: none !important;
        width: auto !important;
        height: auto !important;
        padding: 0.75rem 1rem 0;
        font-size: 0.75rem !important;
        line-height: 1 !important;
        color: #767676 !important;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .algolia-docsearch-footer--logo {
        display: inline !important;
        overflow: visible !important;
        color: inherit !important;
        text-indent: 0 !important;
        background: none !important;
    }
    .algolia-docsearch-suggestion--highlight {
        color: #5f2dab;
        background-color: rgba(154, 132, 187, 0.12);
    }
    .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
        box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, 0.5) !important;
    }
    .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
        background-color: rgba(208, 189, 236, 0.15) !important;
    }
}
.bd-masthead {
    position: relative;
    padding: 3rem ($grid-gutter-width / 2); // background-image: linear-gradient(45deg, #fafafa, #f5f5f5);
    h1 {
        line-height: 1;
    }
    .btn {
        width: 100%;
        padding: 0.8rem 2rem;
        font-size: 1.25rem;
        font-weight: 500;
    }
    .carbonad {
        margin-top: 0 !important;
        margin-bottom: -3rem !important;
    }
    @include media-breakpoint-up(sm) {
        padding-top: 10rem;
        padding-bottom: 10rem;
        .carbonad {
            margin-bottom: 0 !important;
        }
    }
    @include media-breakpoint-up(md) {
        h1 {
            font-size: 4rem;
        }
        .carbonad {
            margin-top: 3rem !important;
        }
    }
}
.half-rule {
    width: 6rem;
    margin: 2.5rem 0;
}
.masthead-followup {
    .bd-clipboard {
        display: none;
    }
    .highlight {
        padding: 0.5rem 0;
        background-color: transparent;
    }
}

//
// Callouts
//
.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: 0.25rem;
    border-radius: 0.25rem;
}
.bd-callout h4 {
    margin-top: 0;
    margin-bottom: 0.25rem;
}
.bd-callout p:last-child {
    margin-bottom: 0;
}
.bd-callout code {
    border-radius: 0.25rem;
}
.bd-callout + .bd-callout {
    margin-top: -0.25rem;
}
@mixin bs-callout-variant($color) {
    border-left-color: $color;
    h4 {
        color: $color;
    }
}
.bd-callout-info {
    @include bs-callout-variant($bd-info);
}
.bd-callout-warning {
    @include bs-callout-variant($bd-warning);
}
.bd-callout-danger {
    @include bs-callout-variant($bd-danger);
}
.iconsElegant .box1 {
    display: inline-block;
    width: 19%;
    padding: 0.25em 0.5em;
    margin: 20px 10px 0 0;
    font-size: 1em;
    color: rgba($body-color, 0.6);
    transition: all 0.2s ease-in-out;
    span {
        color: $body-color;
    }
    &:hover {
        color: $body-color;
        span {
            color: $green;
        }
    }
}
.iconsAwesome {
    .fa-hover {
        > span {
            display: block;
            padding: 0.25em 0;
            overflow: hidden;
            color: rgba($body-color, 0.6);
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: all 0.2s ease-in-out;
            .fa {
                display: inline-block;
                width: 32px;
                margin-right: 10px;
                color: $body-color;
                text-align: right;
                ~ span {
                    color: rgba($body-color, 0.2) !important;
                }
            }
            &:hover {
                color: $body-color;
                .fa {
                    color: $green;
                }
            }
        }
    }
}
@each $color, $value in $theme-colors {
    .swatch-#{$color} {
        color: color-yiq($value);
        background-color: $value !important;
    }
}
@each $color, $value in $colors {
    .swatch-#{$color} {
        color: color-yiq($value);
        background-color: $value !important;
    }
}
@each $color, $value in $grays {
    .swatch-#{$color} {
        color: color-yiq($value);
        background-color: $value !important;
    }
}

// Slick
.slider {
    max-width: 560px;
    margin: 0 auto;
    margin-right: -10px;
    margin-left: -10px;
    h3 {
        position: relative;
        margin: 10px;
        font-size: 36px;
        line-height: 100px;
        color: #fff;
        text-align: center;
        background: $primary;
        + * {
            text-align: center;
        }
    }
    .slick-slide:nth-child(2n) h3 {
        background: $info;
    }
    .image {
        padding: 10px;
        img {
            display: block;
            max-width: 100%;
        }
    }
    /* Arrows */
    .slick-prev,
    .slick-next {
        position: absolute;
        top: 50%;
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        color: transparent;
        cursor: pointer;
        background: $danger;
        border: none;
        border-radius: 50%;
        outline: none;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: transparent;
        background: darken($danger, 10%);
        outline: none;
    }
    .slick-prev:hover::before,
    .slick-prev:focus::before,
    .slick-next:hover::before,
    .slick-next:focus::before {
        opacity: 1;
    }
    .slick-prev.slick-disabled::before,
    .slick-next.slick-disabled::before {
        opacity: 0.25;
    }
    .slick-prev::before,
    .slick-next::before {
        font-family: 'eleganticons';
        font-size: 20px;
        line-height: 1;
        color: white;
        opacity: 0.75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev {
        left: 0;
    }
    [dir='rtl'] .slick-prev {
        right: 0;
        left: auto;
    }
    .slick-prev::before {
        content: '\34';
    }
    [dir='rtl'] .slick-prev::before {
        content: '\35';
    }
    .slick-next {
        right: 0;
    }
    [dir='rtl'] .slick-next {
        right: auto;
        left: 0;
    }
    .slick-next::before {
        content: '\35';
    }
    [dir='rtl'] .slick-next::before {
        content: '\34';
    }
    /* Dots */
    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }
    .slick-dots {
        position: absolute;
        bottom: -16px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
        list-style: none;
    }
    .slick-dots li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0 5px;
        cursor: pointer;
    }
    .slick-dots li button {
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        font-size: 0;
        line-height: 0;
        color: transparent;
        cursor: pointer;
        background: transparent;
        border: 0;
        outline: none;
    }
    .slick-dots li button:hover,
    .slick-dots li button:focus {
        outline: none;
    }
    .slick-dots li button:hover::before,
    .slick-dots li button:focus::before {
        opacity: 1;
    }
    .slick-dots li button::before {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        content: '';
        background: $danger;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots li.slick-active button::before {
        color: black;
        opacity: 0.75;
    }
    @include media-breakpoint-up(lg) {
        .slick-prev {
            left: -25px;
        }
        [dir='rtl'] .slick-prev {
            right: -25px;
            left: auto;
        }
        .slick-next {
            right: -25px;
        }
        [dir='rtl'] .slick-next {
            right: auto;
            left: -25px;
        }
    }

    // Theme
    &.center {
        h3 {
            opacity: 0.8;
            transition: all 300ms ease;
        }
        .slick-center h3 {
            color: #e67e22;
            opacity: 1;
            -moz-transform: scale(1.08);
            -ms-transform: scale(1.08);
            -o-transform: scale(1.08);
            -webkit-transform: scale(1.08);
            transform: scale(1.08);
        }
    }
}

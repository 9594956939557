.banner {
    display: flex;
    align-items: center;
    height: 160px;
    background: url('/images/header-bg.png') no-repeat right -30px #aadee5;
    .img-bg {
        &::after {
            padding-top: 19%;
        }
    }
}
.largeBanner {
    position: relative;
    &_wrapper {
        position: relative;
        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            pointer-events: none;
            content: '';
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
    &_image {
        position: relative;
        z-index: 1;
        .img-bg {
            &::after {
                padding-top: 30.21572%;
                @include media-breakpoint-down(sm) {
                    padding-top: 56.21572%;
                }
            }
            img {
                display: none !important;
            }
        }
    }
    &_title {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        text-align: center;
        transform: translate((-50%, -50%));
        h1 {
            font-weight: 700;
            color: gainsboro;
            text-shadow: 0 1px 0 #caaaaa, 0 2px 0 #ef6c6c, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #fff816, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(243, 54, 54, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
                0 20px 20px rgba(0, 0, 0, 0.15);
            text-transform: uppercase;
            @include media-breakpoint-up(xl) {
                font-size: 80px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 25px;
            }
        }
    }
}

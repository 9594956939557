.s-sliders {
    margin-bottom: 40px;
    .sectionTitle {
        padding: 4px 10px;
        margin-bottom: 10px;
        background-color: $mainColor;
        h2 {
            margin-bottom: 0;
            font-size: 25px;
            color: #fff;
            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }
        }
    }
    .innerSection {
        overflow: hidden;
        .sliderContent {
            margin-right: -8px;
            margin-left: -8px;
            .verticalPost {
                padding-right: 8px;
                padding-left: 8px;
                &_context {
                    margin-top: 4px;
                    a {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .slick-arrow {
        top: 35%;
        width: 30px;
        height: 30px;
        background-color: $mainColor;
    }
    .slick-prev {
        left: 20px;
        &::before {
            position: absolute;
            top: 22px;
            left: 22px;
            width: 8px;
            height: 8px;
            content: '';
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transition: 0.4s ease;
            transform: rotate(135deg);
        }
    }
    .slick-next {
        right: 20px;
        &::before {
            position: absolute;
            top: 22px;
            left: 20px;
            width: 8px;
            height: 8px;
            content: '';
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transition: 0.4s ease;
            transform: rotate(-45deg);
        }
    }
}

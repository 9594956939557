/* Comment here */
.bd-navbar {
    background-color: #354b9c !important;
}
section#navArea {
    margin-bottom: 24px;
    background-color: #354b9c;
    nav.navbar {
        padding-top: 0;
        padding-bottom: 0;
        @include media-breakpoint-up(xl) {
            justify-content: center;
        }
        @include media-breakpoint-down(lg) {
            display: block;
        }
        #navbar.collapse {
            @include media-breakpoint-up(xl) {
                display: block !important;
            }
        }
        ul.nav {
            @include media-breakpoint-down(lg) {
                display: block;
            }
            @include media-breakpoint-down(md) {
                max-width: 400px;
                margin: auto;
            }
            li.nav-item {
                position: relative;
                &:hover {
                    .navlink {
                        color: #354b9c;
                        background-color: #fff;
                        &.hasSub {
                            &::before {
                                border-top: 6px solid $mainColor;
                            }
                        }
                    }
                    ul.subNav {
                        visibility: visible;
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
                .navlink {
                    display: inline-block;
                    height: 50px;
                    padding: 0 14px;
                    line-height: 50px;
                    color: #ddd;
                    text-decoration: none !important;
                    text-shadow: 0 1px 1px #000;
                    text-transform: uppercase;
                    transition: all 0.5s;
                    &.hasSub {
                        padding-right: 16px;
                        &::before {
                            position: absolute;
                            top: calc(50% - 3px);
                            right: 3px;
                            display: inline-block;
                            width: 0;
                            height: 0;
                            content: '';
                            border-top: 6px solid #fff;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                            transition: 0.4s ease;
                        }
                    }
                }
            }
        }
        ul.subNav {
            position: absolute;
            top: 50px;
            left: 0;
            z-index: 3;
            width: 250px;
            visibility: hidden;
            background-color: #fff;
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
            opacity: 0;
            transition: 0.4s ease;
            transform: translateX(-30px);
            li {
                a {
                    text-decoration: none !important;
                    text-transform: capitalize;
                }
            }
        }
        .wrap-btn-collapse {
            display: flex;
            justify-content: flex-end;
            @include media-breakpoint-up(xl) {
                display: none;
            }
            .navbar-toggle-collapsed {
                position: relative;
                padding: 20px;
                margin-top: 8px;
                margin-right: 15px;
                margin-bottom: 8px;
                background-color: transparent;
                background-image: none;
                border: 1px solid #fff;
                border-radius: 4px;
                outline: none;
                &::before,
                &::after,
                span {
                    position: absolute;
                    right: 8px;
                    left: 8px;
                    height: 2px;
                    content: '';
                    background-color: #fff;
                }
                &::before {
                    top: 10px;
                }
                &::after {
                    top: 18px;
                }
                span {
                    top: 26px;
                }
            }
        }
    }
}
.form-search {
    margin-right: 12px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 7px;
    }
    input {
        padding: 4px 12px;
        font-size: 12px;
        border: none;
        border-radius: 4px;
        outline: none;
    }
}

// Sample code
.masthead {
    padding: 200px 0;
    text-align: center;
    background-color: #fff;
}
main.wrapper {
    padding-bottom: 100px;
    overflow: hidden;
    background-color: #fff;
}
.img-bg {
    img {
        display: none !important;
    }
}

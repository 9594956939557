@import 'icons-ElegantIcons';
@import 'icons-FontAwesomeIcon';
i.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &.ic-facebook {
        width: 24px;
        height: 24px;
        background-image: url('../images/icons/facebook.svg');
    }
    &.ic-mail {
        width: 24px;
        height: 24px;
        background-image: url('../images/icons/gmail.svg');
    }
    &.ic-youtube {
        width: 24px;
        height: 24px;
        background-image: url('../images/icons/youtube.svg');
    }
}

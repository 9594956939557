// Normal
// @font-face {
//     font-family: 'Elle Futura';
//     src: url('#{$font-path}ElleFutura.eot?#iefix') format('embedded-opentype'), url('#{$font-path}ElleFutura.woff') format('woff'), url('#{$font-path}ElleFutura.ttf') format('truetype'), url('#{$font-path}ElleFutura.svg#ElleFutura') format('svg');
//     font-weight: 400;
//     font-style: normal;
// }
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: bold;
    src: url('#{$font-path}Roboto-BoldItalic.woff2') format('woff2'), url('#{$font-path}Roboto-BoldItalic.woff') format('woff'), url('#{$font-path}Roboto-BoldItalic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}Roboto-Bold.woff2') format('woff2'), url('#{$font-path}Roboto-Bold.woff') format('woff'), url('#{$font-path}Roboto-Bold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: normal;
    src: url('#{$font-path}Roboto-Italic.woff2') format('woff2'), url('#{$font-path}Roboto-Italic.woff') format('woff'), url('#{$font-path}Roboto-Italic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}Roboto-Medium.woff2') format('woff2'), url('#{$font-path}Roboto-Medium.woff') format('woff'), url('#{$font-path}Roboto-Medium.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('#{$font-path}Roboto-Light.woff2') format('woff2'), url('#{$font-path}Roboto-Light.woff') format('woff'), url('#{$font-path}Roboto-Light.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('#{$font-path}Roboto-Regular.woff2') format('woff2'), url('#{$font-path}Roboto-Regular.woff') format('woff'), url('#{$font-path}Roboto-Regular.ttf') format('truetype');
    font-display: swap;
}

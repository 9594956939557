.block-content {
    margin-bottom: 30px;
}
.row {
    &.customRow {
        margin-right: -8px;
        margin-left: -8px;
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-10,
        .col-11,
        .col-12,
        .col,
        .col-auto,
        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm,
        .col-sm-auto,
        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-10,
        .col-md-11,
        .col-md-12,
        .col-md,
        .col-md-auto,
        .col-lg-1,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12,
        .col-lg,
        .col-lg-auto,
        .col-xl-1,
        .col-xl-2,
        .col-xl-3,
        .col-xl-4,
        .col-xl-5,
        .col-xl-6,
        .col-xl-7,
        .col-xl-8,
        .col-xl-9,
        .col-xl-10,
        .col-xl-11,
        .col-xl-12,
        .col-xl,
        .col-xl-auto {
            padding-right: 8px;
            padding-left: 8px;
        }
    }
}
.s-flexPanel {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    &_img {
        position: relative;
        flex: 33.33333%;
        max-width: 33.33333%;
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        &_wrapper {
            overflow: hidden;
            &:hover {
                .img-bg {
                    transform: scale(1.12);
                }
            }
        }
        a {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: 75%;
            overflow: hidden;
        }
        .imgThumb {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.4s ease;
        }
        .img-bg {
            transition: 0.4s ease;
            &::after {
                padding-top: 150/185 * 100%;
            }
        }
    }
    &_item {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        &:first-child {
            @include media-breakpoint-down(sm) {
                margin-bottom: 14px;
            }
        }
        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
